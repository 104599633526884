<template>
	<div v-if="loaded" id="opacityPage" class="opacity-page">
		<template v-if="applications.filter(appData => appData.is_active).length">
			<div class="header-section row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="title">{{ $t('dashboard.active_apps') }}</div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="row-space-tbf">
				<div class="space-left"></div>			
				<div class="content">
					<div class="applications-list">
						<div class="application-space" v-for="applicationData in applications.filter(appData => appData.is_active)">
							<div class="application-box">
								<div class="header">
									<div class="icon"><img :src="applicationData.application.image"></div>
									<div class="title">{{ applicationData.application.name }}</div>
								</div>
								<div class="description">{{ $t('dashboard_subtitles.'+applicationData.application.name)}}</div>
								<a :href="applicationData.application.fe_host" class="btn-tbf blue center action">
									<div class="text">{{ $t('dashboard.go_app') }}</div>
								</a>
							</div>
						</div>
					</div>
				</div>			
				<div class="space-right"></div>
			</div>
		</template>

		<template v-if="applications.filter(appData => !appData.is_active).length">
			<div class="header-section row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="title">{{ $t('dashboard.inactive_apps') }}</div>
				</div>
				<div class="space-right"></div>
			</div>
			
			<div class="row-space-tbf">
				<div class="space-left"></div>			
				<div class="content">
					<div class="applications-list">
						<div class="application-space" v-for="applicationData in applications.filter(appData => !appData.is_active)">
							<div class="application-box">
								<div class="header">
									<div class="icon"><img :src="applicationData.application.image"></div>
									<div class="title">{{ applicationData.application.name }}</div>
								</div>
								<!-- <div class="description">Praesent suspendisse nulla proin habitasse feugiat rutrum odio inceptos massa neque sociosqu blandit donec gravida</div> -->
								<router-link :to="{name: 'pricing'}" class="btn-tbf grey center action">
									<div class="text">{{ $t('dashboard.activate_app') }}</div>
								</router-link>
							</div>
						</div>
					</div>
				</div>			
				<div class="space-right"></div>
			</div>
		</template>

	</div>
	<div v-else>
		<div class="header-section row-space-tbf">
			<div class="space-left"></div>
			<div class="content">
				<div class="placeholder-loader" style="height: 19px; width: 150px;"></div>
			</div>
			<div class="space-right"></div>
		</div>

		<div class="row-space-tbf">
			<div class="space-left"></div>			
			<div class="content">
				<div class="applications-list">
					<div class="application-space" v-for="n in 4">
						<div class="application-box">
							<div class="header">
								<div class="icon"><div class="placeholder-loader" style="height: 30px; width: 30px; margin-right: 30px;"></div></div>
								<div class="title"><div class="placeholder-loader" style="height: 24px; width: 150px;"></div></div>
							</div>
							<div class="placeholder-loader" style="height: 50px; width: 100%; margin-bottom: 15px;"></div>
							<div class="placeholder-loader" style="height: 40px; width: 100%;"></div>
						</div>
					</div>
				</div>
			</div>			
			<div class="space-right"></div>
		</div>
	</div>
</template>

<script>
	import IconOrganigram from '../Icons/Organigram'
	import IconProcedures from '../Icons/Procedures'

	export default {
		components: {
			IconOrganigram,
			IconProcedures
		},
		data() {
			return {
				loaded: false,
				applications: [],
			}
		},
		async mounted() {
			setTimeout(() => {
				var title = this.$t('navbar.dashboard');
				this.$root.$emit("navbar_title", title);
			}, 0)

			await this.getApps()
		},
		methods: {
			async getApps(){
				await axios.get('/user-applications')
				.then(({data}) => {
					this.applications = data.data
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							document.getElementById('opacityPage').classList.add('show')
						}, 0)
					}, 0)
				})
			}
		}
	}
</script>

<style lang="scss">
    @import "../../styles/pages/dashboard.scss";
</style>